<template>
  <div v-if="$store.getters.messages" :key="total">

    <messageCenterHeader :total="total" />

    <div v-if="flixNews.length" class="flix-container">
      <h2 class="flix-html-h3">{{ $tc("message.systemNews", flixNews.length) }} ({{ flixNews.length }})</h2>

        <div v-for="(chat, index) in flixNews" :key="chat.ID">
          <a v-if="index < 3 && chat.link" href="#" @click.prevent="openPage(chat)" class="flix-html-a">
            <messageListItem >
              <template v-slot:title>{{ chat.title }}</template>
              <template v-slot:text><span v-html="chat.text" /></template>
              <template v-slot:date><span v-html="chat.date" /></template>
            </messageListItem>
          </a>
          <messageListItem v-if="index < 3 && !chat.link">
            <template v-slot:title>{{ chat.title }}</template>
            <template v-slot:text><span v-html="chat.text" /></template>
            <template v-slot:date><span v-html="chat.date" /></template>
          </messageListItem>
        </div>

    </div>

    <userChats v-if="userchats" :data="userchats" :callback="function(chat){openUserChat(chat)}"/>

  </div>
</template>
<script>
export default {
  components: {
    messageListItem () { return import('@/components/messages/list') },
    messageCenterHeader () { return import('@/components/messages/header') },
    userChats () { return import('@/components/messages/userchatsMenu') }
  },
  props: {},
  data () {
    return {
      total: 0,
      flixNews: false,
      userchats: false,
      variables: this.$getUserVariables()
    }
  },
  methods: {
    openPage (chat) {
      if (typeof chat.link === 'object') {
        this.$router.push(chat.link)
        return false
      }
      return false
    },
    openUserChat (chat) {
      if (typeof chat.link === 'object') {
        this.$router.push(chat.link)
        return false
      }
      var ID = chat.ID.split('/')
      if (ID.length > 1) {
        this.$router.push({
          name: 'dashboardBookingDetails',
          params: {
            user: ID[0],
            form: ID[1],
            entryID: ID[2]
          }
        })
      }
    },
    reorderUserChats (chats) {
      var r = []
      var i = 0
      chats.forEach(function (k) {
        var date = k.date.split(' ')
        var Ymd = date[3].split('.')
        var His = date[7].split(':')
        r.push(Ymd[2] + this.$getNullsBefore(Ymd[1]) + this.$getNullsBefore(Ymd[0]) + this.$getNullsBefore(His[0]) + this.$getNullsBefore(His[1]) + '-' + i)
        i++
      }.bind(this))
      r.sort()
      r.reverse()

      var ret = []
      r.forEach(function (k) {
        var ID = k.split('-')
        ret.push(chats[ID[1]])
      })
      return ret
    },
    getChatMessages () {
      var l = 0
      Object.values(this.$store.getters.messages).forEach(function (v) {
        l += Object.keys(v).length
      })
      return l
    },
    getDeletionMsg () {
      this.$getLastMonthDays({
        days: 2,
        callback: function () {
          this.$store.dispatch('getDeletionList', {
            user: this.variables.user.md5_id,
            callback: function (ret) {
              if (ret) {
                var date = new Date()
                var link = this.$router.resolve({ name: 'dashboardDownloadCenter', params: { id: 'all' } })
                this.flixNews.push({
                  title: ret + ' ' + this.$tc('message.customer', ret),
                  text: this.$nl2br(this.$t('message.deletionMsg').split('%count').join(ret).split('%date').join(this.getNextMonth())) + '<br><br><a href="' + link.href + '" class="flix-btn flix-btn-default">' + this.$t('message.csvDownload') + '</a>',
                  date: this.$getNullsBefore(date.getDate()) + '.' + this.$getNullsBefore((date.getMonth() + 1)) + '.' + date.getFullYear() + ' ' + this.$getNullsBefore(date.getHours()) + ':' + this.$getNullsBefore(date.getMinutes())
                })
                this.total += 1
              }
            }.bind(this)
          })
        }.bind(this)
      })
    },
    getNextMonth () {
      var d = new Date()
      d.setMonth(d.getMonth() + 1)
      d.setDate(1)

      // if (d.getMonth() === 9 && d.getFullYear() === 2020) {
      // d.setMonth(d.getMonth() + 1)
      // }

      var weekday = d.getDay()
      weekday = weekday - 1

      if (weekday < 0) {
        weekday = 6
      }
      return this.$t('message.weekdays')[weekday] + ', ' + this.$getNullsBefore(d.getDate()) + '.' + this.$getNullsBefore((d.getMonth() + 1)) + '.' + d.getFullYear()
    }
  },
  mounted () {
    this.$store.commit('cleanMessages')
    this.$store.dispatch('getMessages', function () {
      this.total = this.total + this.getChatMessages()

      if (typeof this.$store.getters.messages.userchat !== 'object') {
        this.userchats = false
      } else {
        this.userchats = this.reorderUserChats(this.$store.getters.messages.userchat)
      }

      if (typeof this.$store.getters.messages.flixNews !== 'object') {
        this.flixNews = []
      } else {
        this.flixNews = this.$store.getters.messages.flixNews
      }
      this.getDeletionMsg()
      this.flixNews = this.reorderUserChats(this.flixNews)
    }.bind(this))
  }
}
</script>
<style lang="sass" scoped>
</style>
